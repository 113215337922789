import React from 'react';
import { navigate } from "gatsby"
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useForm } from "react-hook-form";
import useConfirm from '../../hooks/useConfirm'
import Submit from "../../components/atoms/submit";
import AuthService from "../../services/auth"
import Progress from "../../components/Progress";
import ConfirmForm from '../../components/pages/auth/confirm';

const useStyles = makeStyles(() => ({
    root: {}
}));

const Account = () => {
    const classes = useStyles();
    const confirm = useConfirm();
    const { register, handleSubmit, errors } = useForm();
    const [user, setUser] = React.useState({});

    React.useEffect(() => {
        const getUser = async () => {
            await AuthService.getUser()
                .then(async response => {
                    setUser({
                        CognitoUser: response,
                        id: response.username,
                        userName: response.attributes.name,
                        tel: !!response.attributes.phone_number ? response.attributes.phone_number.replace('+81', '') : null,
                        email: response.attributes.email,
                    })
                })
                .catch(() => {
                    confirm({ alert: true, description: 'セッションがタイムアウトしました。再度ログインしてください。' })
                        .then(() => navigate('/'))
                })
        }

        getUser()
    }, []) // eslint-disable-line

    const doSubmit = async data => {
        await AuthService.updateAttributes(
            user.CognitoUser,
            {
                name: data.userName,
                phone_number: `+81${data.tel}`,
                email: data.email
            },
        )
        handleOpenConfirm()
    };

    // for confirm code
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    return (
        <>
            {user.userName ? (
                <>
                    <ConfirmForm name={user.userName} username={user.id} open={openConfirm} handleClose={handleCloseConfirm} />
                    <form
                        autoComplete="off"
                        noValidate
                        className={classes.root}
                    >
                        <Card>
                            <CardHeader
                                subheader="電話番号・メールアドレス等変更ございましたら、変更くださいませ。"
                                title="お客さま情報"
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            required
                                            id="userName"
                                            name="userName"
                                            label="お名前"
                                            defaultValue={user.userName}
                                            fullWidth
                                            inputRef={register({ required: true })}
                                            error={Boolean(errors.name)}
                                            helperText={errors.name && "入力してください"}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            required
                                            id="tel"
                                            name="tel"
                                            label="電話番号"
                                            defaultValue={user.tel}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                                pattern: {
                                                    value: /^[0-9]{10,11}$/i
                                                }
                                            })}
                                            helperText={errors.tel && "数値のみで入力してください(10-11桁)"}
                                            error={Boolean(errors.tel)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            required
                                            id="email"
                                            name="email"
                                            label="メールアドレス"
                                            defaultValue={user.email}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                                pattern: {
                                                    value: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i, // eslint-disable-line
                                                }
                                            })}
                                            error={Boolean(errors.email)}
                                            helperText={errors.email && "メールアドレスを入力してください"}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                p={2}
                            >
                                <Submit
                                    onClick={handleSubmit(doSubmit)}
                                >
                                    更新
                                </Submit>
                            </Box>
                        </Card>
                    </form>
                </>
            ) : (
                    <Progress processing={true} />
                )
            }
        </>)
}

export default Account;